import { Dispatch } from "redux";
import IAddress from "../../../interfaces/IAddress";
import { IResponse, RACARequest } from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";

export default async function (
  dispatch: Dispatch,
  address: string
): Promise<IResponse<IAddress[]>> {
  const request = new RACARequest("utils", "find-location").setQueryParameters({
    address: address,
  });

  const response = await request.makeRequest();
  requestMiddlewares(dispatch, response);

  return response.data;
}
