import IAddress from "../../core/interfaces/IAddress";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import {Root} from "react-dom/client";
import {RootState} from "../../core/store/store";
import axios from "axios";
import findLocationRequest from "../../core/api/requests/utils/findLocationRequest";

export interface IInput {
  onSubmit(address: IAddress): void
  onClose(): void;
  show: boolean;
}

export default function (data: IInput) {
  const dispatch = useDispatch()
  const session = useSelector((state: RootState) => state.session)

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ name, setName ] = useState<string>()
  const [ address, setAddress ] = useState<IAddress>()
  const [ addressList, setAddressList ] = useState<IAddress[]>([])

  const [ timeout, setTO ] = useState<NodeJS.Timeout>()
  const [ query, setQuery ] = useState<string>('')

  const find = async (text: string) => {
    setQuery(text)
  }

  const list = async () => {
    setLoading(true)

    const l = await searchLocations(query)
    if (l) {
      setAddressList([
        ...l
      ])
    }
    setLoading(false)
  }

  useEffect(() => {
    const to = setTimeout(() => list(), 500)

    if (timeout) {
      clearTimeout(timeout)
    }

    setTO(to)
  }, [query]);

  const send = () => {
    if (!address) return

    data.onSubmit(address);

    data.onClose();
  }

  const setadd = (a: any) => {
    if (a && typeof a !== 'string') {
      setAddress(a)
    }
  }

  const searchLocations = async (location: string): Promise<IAddress[]> => {
    // If we search a location, we need input a 10 chars
    if (location.length < 10) return [];

    try {
      const req = await findLocationRequest(dispatch, location)
      return req.data
    } catch (e: any) {
      console.log(e)
      return []
    }
  }

  useEffect(() => {

    return () => {
      setName('')
    }
  }, [data.show])

  return <Modal onHide={data.onClose} show={data.show}>
    <Modal.Header closeButton>
      <Modal.Title>Cambiar ubicación</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Autocomplete
        freeSolo={true}
        loading={loading}
        filterOptions={(x) => x}
        getOptionLabel={(option: any) => option.fullAddress || ''}
        options={addressList}
        onChange={(e, value) => setadd(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => find(e.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cancelar
      </Button>
      <Button
        variant="success"
        onClick={send}
      >
        Modificar
      </Button>
    </Modal.Footer>
  </Modal>
}
