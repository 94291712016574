import { Dispatch } from "redux";
import {
	endLoading,
	startLoading,
} from "../../../../store/slices/loadingSlice";
import { IResponse, RACARequest } from "../../../Request";
import requestMiddlewares from "../../../requestMiddlewares";

export interface IUpdateCircuitEventRequest {
	id: string;
	name: string;
	startDate: Date;
	description: string;
	endDate: Date;
	joined?: boolean;
	price: number;
	createdAt: Date;
	kart?: string;
	maxUser: number;
}

export default async function (
	dispatch: Dispatch,
	input: IUpdateCircuitEventRequest
): Promise<IResponse> {
	dispatch(startLoading());
	const request = new RACARequest("circuits", "events-update").setData(input);

	const response = await request.makeRequest();
	requestMiddlewares(dispatch, response);

	dispatch(endLoading());

	return response;
}
