import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import sweetalert2 from "sweetalert2";
import findByIdJourneyRequest from "../../core/api/requests/utils/findByIdJourneyRequest";
import modifyJourneyRequest from "../../core/api/requests/utils/modifyJourneyRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import IJourney from "../../core/interfaces/IJourney";
import IJourneyUser from "../../core/interfaces/IJourneyUser";

type IInput = {
	onClose(): void;
	show: boolean;
	journeyId?: string;
};

export default function (data: IInput) {
	const dispatch = useDispatch();

	const [journey, setJourney] = useState<IJourney>();

	const get = async () => {
		if (!data.journeyId) return;

		const response = await findByIdJourneyRequest(dispatch, data.journeyId);

		if (response) {
			setJourney(response.data);
		}
	};

	const approve = async () => {
		if (!journey?.id) return;

		const response = await modifyJourneyRequest(dispatch, journey.id, true);

		if (response && response.statusCode === EHttpStatusCodes.OK) {
			sweetalert2
				.fire(`Excelente`, `Registro aprobado exitosamente.`, "success")
				.then(() => {
					data.onClose();
				});
		} else {
			sweetalert2.fire(`Error`, "Error al aprobar", "error");
		}
	};

	const deny = async () => {
		if (!journey?.id) return;

		const response = await modifyJourneyRequest(dispatch, journey.id, false);

		if (response && response.statusCode === EHttpStatusCodes.OK) {
			sweetalert2
				.fire(`Excelente`, `Registro desaprobado exitosamente.`, "success")
				.then(() => {
					data.onClose();
				});
		} else {
			sweetalert2.fire(`Error`, "Error al desaprobar", "error");
		}
	};

	useEffect(() => {
		console.log(data.journeyId);
		get();
	}, [data.journeyId]);

	if (!journey) return <div></div>;
	return (
		<Modal size={"xl"} onHide={data.onClose} show={data.show}>
			<Modal.Header closeButton>
				<Modal.Title>Validar Jornada</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Form.Group className="mb-3 col-6">
						<Form.Label>ID</Form.Label>
						<Form.Control type="text" disabled value={journey?.id} />
					</Form.Group>

					<Form.Group className="mb-3 col-6">
						<Form.Label>Fecha</Form.Label>
						<Form.Control
							type="text"
							disabled
							value={moment(journey.date).format("DD/MM/YYYY HH:mm:ss")}
						/>
					</Form.Group>

					<Form.Group className="mb-3 col-6">
						<Form.Label>Circuito ID</Form.Label>
						<Form.Control type="text" disabled value={journey?.circuit.id} />
					</Form.Group>

					<Form.Group className="mb-3 col-6">
						<Form.Label>Nombre del Circuito</Form.Label>
						<Form.Control type="text" disabled value={journey?.circuit.name} />
					</Form.Group>

					<Form.Group className="mb-3 col-4">
						<Form.Label>Kart</Form.Label>
						<Form.Control type="text" disabled value={journey?.kart} />
					</Form.Group>

					<Form.Group className="mb-3 col-4">
						<Form.Label>Tiempo Referencia Kart</Form.Label>
						<Form.Control
							type="text"
							disabled
							value={
								journey?.circuit.kartingTimes
									? journey?.circuit.kartingTimes.reduce((best, current) => {
											if (
												(current.kart === journey?.kart && !best) ||
												(best && current.time < best.time && current.kart === journey?.kart)
											) {
												return current;
											} else {
												return best;
											}
									  }, null).time
									: ""
							}
						/>
					</Form.Group>

					<Form.Group className="mb-3 col-4">
						<Form.Label>Estado</Form.Label>
						<Form.Control type="text" disabled value={journey?.status} />
					</Form.Group>

					<Form.Group className="mb-3 col-4">
						<Form.Label>Tipo de clasificación</Form.Label>
						<Form.Control type="text" disabled value={journey?.classificationType} />
					</Form.Group>

					<Col xs={12} className={"mt-3 mb-3"}>
						<Table bordered={true}>
							<thead>
								<tr>
									<th>Usuario</th>
									<th>Tiempo</th>
									<th>Puntos</th>
									<th>Posición</th>
								</tr>
							</thead>
							<tbody>
								{journey.users.map((u: IJourneyUser) => {
									return (
										<tr key={u.user.id}>
											<td>
												{u.user.userName} - {u.user.email}
											</td>
											<td>{u.time}</td>
											<td>{u.points}</td>
											<td>{u.position}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</Col>

					{journey?.validationFile && (
						<Col sm={12}>
							<embed src={journey.validationFile} width={"100%"} height={900} />
						</Col>
					)}
				</Row>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={data.onClose}>
					Cancelar
				</Button>

				{journey.status != "verified" && (
					<div>
						<Button variant="danger" onClick={deny}>
							Denegar
						</Button>
						<Button variant="success" className={"ml-2"} onClick={approve}>
							Aprobar
						</Button>
					</div>
				)}
			</Modal.Footer>
		</Modal>
	);
}
