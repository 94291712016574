import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import sweetalert2 from "sweetalert2";
import findByIdEventRequest from "../../core/api/requests/circuits/events/findByIdEventRequest";
import listEventInscriptionsRequest from "../../core/api/requests/circuits/events/listEventInscriptionsRequest";
import removeEventRequest from "../../core/api/requests/circuits/events/removeEventRequest";
import uploadEventPictureRequest from "../../core/api/requests/circuits/events/uploadEventPictureRequest";
import ICircuitEvent from "../../core/interfaces/ICircuitEvent";
import ICircuitEventInscription from "../../core/interfaces/ICircuitEventInscription";
import { RootState } from "../../core/store/store";
import Defaults from "../../core/utils/defaults";
import ProfileImage from "../lib/ProfileImage";

type IInput = {
	onClose(): void;
	show: boolean;
	id: string;
};

export default function (data: IInput) {
	const dispatch = useDispatch();
	const selectedCircuit = useSelector(
		(state: RootState) => state.selectedCompany
	);

	const [event, setEvent] = useState<ICircuitEvent>();
	const [inscriptions, setInscriptions] = useState<ICircuitEventInscription[]>(
		[]
	);

	const get = async () => {
		if (!data.id || !selectedCircuit.circuit) return;

		const response = await findByIdEventRequest(dispatch, data.id);

		if (response) {
			setEvent(response.data);
		}
	};

	const changeImage = async (file: File) => {
		if (!file || !selectedCircuit.circuit?.id) return;

		await uploadEventPictureRequest(
			dispatch,
			file,
			selectedCircuit.circuit.id,
			data.id
		);

		data.onClose();
	};

	const getInscriptions = async () => {
		if (!data.id || !selectedCircuit.circuit) return;

		const response = await listEventInscriptionsRequest(
			dispatch,
			selectedCircuit.circuit.id,
			data.id
		);

		if (response.data) {
			setInscriptions(response.data.data);
		}
	};

	const remove = () => {
		sweetalert2
			.fire({
				title: "Atención!",
				text: `¿Seguro que desea eliminar este evento?`,
				showCancelButton: true,
				confirmButtonText: "Eliminar",
				cancelButtonText: "Cancelar",
				icon: "question",
			})
			.then((o) => {
				if (o.isConfirmed) removeRequest();
			});
	};

	const removeRequest = async () => {
		if (!data.id || !selectedCircuit.circuit) return;

		const response = await removeEventRequest(
			dispatch,
			selectedCircuit.circuit.id,
			data.id
		);

		if (response && response.statusCode === 200) {
			sweetalert2
				.fire(`Excelente`, `Evento eliminado exitosamente.`, "success")
				.then(() => {
					data.onClose();
				});
		} else {
			sweetalert2.fire(`Error`, "Error al eliminar", "error");
		}
	};

	const downloadPDF = async () => {
		const input = document.getElementById("inscriptionsTable"); // Asegúrate de que el div tenga este ID
		if (!input) return;

		// Clonar el contenido del div para evitar problemas de scroll
		const clone = input.cloneNode(true) as HTMLElement;
		clone.querySelectorAll("th").forEach((el) => {
			el.style.fontSize = "28px";
		});
		clone.querySelectorAll("td").forEach((el) => {
			el.style.fontSize = "28px";
		});
		clone.style.overflow = "visible"; // Asegurarse de que el overflow sea visible
		document.body.appendChild(clone); // Agregar el clon al body temporalmente

		const canvas = await html2canvas(clone, { scale: 5, useCORS: true });
		document.body.removeChild(clone); // Eliminar el clon después de capturar

		const imgData = canvas.toDataURL("image/png");
		const pdf = new jsPDF();
		const imgWidth = pdf.internal.pageSize.getWidth() - 40;
		const pageHeight = pdf.internal.pageSize.height;
		const imgHeight = (canvas.height * imgWidth) / canvas.width;
		let heightLeft = imgHeight;

		const logoImg = await fetch("/icons/logo-transparent-red.png")
			.then((res) => res.blob())
			.then(
				(blob) =>
					new Promise((resolve) => {
						const reader = new FileReader();
						reader.onloadend = () => resolve(reader.result);
						reader.readAsDataURL(blob);
					})
			);
		pdf.addImage(logoImg as string, "PNG", 150, 10, imgWidth - 120, 10);
		let position = 25;

		pdf.addImage(imgData, "PNG", 20, position, imgWidth, imgHeight);
		heightLeft -= pageHeight;

		while (heightLeft >= 0) {
			position = heightLeft - imgHeight;
			pdf.addPage();
			pdf.addImage(imgData, "PNG", 20, position, imgWidth, imgHeight);
			heightLeft -= pageHeight;
		}

		pdf.save(`${event?.name}.pdf`);
	};

	useEffect(() => {
		get();
		getInscriptions();
	}, [data.id]);

	if (!event) return <div></div>;
	return (
		<Modal onHide={data.onClose} show={data.show}>
			<Modal.Header closeButton>
				<Modal.Title>Administrar Evento</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col sm={12}>
						<ProfileImage
							uri={event.image || Defaults.events}
							onChange={changeImage}
						/>
					</Col>

					<Form.Group className="mb-3 col-12">
						<Form.Label>ID</Form.Label>
						<Form.Control type="text" disabled value={event?.id} />
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Fecha</Form.Label>
						<Form.Control
							type="text"
							disabled
							value={moment(event.startDate).format("DD/MM/YYYY HH:mm:ss")}
						/>
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Circuito ID</Form.Label>
						<Form.Control type="text" disabled value={event?.circuit.id} />
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Nombre Circuito</Form.Label>
						<Form.Control type="text" disabled value={event?.circuit.name} />
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Nombre</Form.Label>
						<Form.Control type="text" disabled value={event?.name} />
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Precio (€)</Form.Label>
						<Form.Control type="number" disabled value={event?.price} />
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Kart</Form.Label>
						<Form.Control type="text" disabled value={event?.kart} />
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Descripción</Form.Label>
						<Form.Control type="text" disabled value={event?.description} />
					</Form.Group>

					<Col>
						<Form.Label>Inscripciones</Form.Label>
						<div id="inscriptionsTable" style={{ overflowY: "auto" }}>
							<Table bordered>
								<thead>
									<tr>
										<th>Nro</th>
										<th>Nombre de Usuario</th>
										<th>Nombre</th>
										<th>Apellido</th>
										<th>Email</th>
										<th>Teléfono</th>
										<th>Nivel</th>
									</tr>
								</thead>
								<tbody>
									{inscriptions.map((i, index) => (
										<tr key={i.user.id}>
											<td>{index + 1}</td>
											<td>{i.user.userName}</td>
											<td>{i.user.name}</td>
											<td>{i.user.lastName}</td>
											<td>{i.user.email}</td>
											<td>
												{i.user.phoneNumbers && i.user.phoneNumbers.length > 0
													? i.user.phoneNumbers[0].number
													: "N/A"}
											</td>
											<td>{i.user.level.toFixed(2)}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</Col>
				</Row>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={downloadPDF}>
					Descargar lista
				</Button>

				<Button variant="secondary" onClick={data.onClose}>
					Cancelar
				</Button>

				{inscriptions.length === 0 && (
					<Button variant="danger" onClick={remove}>
						Eliminar
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
}
